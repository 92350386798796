import FacebookOutlined from "@ant-design/icons/lib/icons/FacebookOutlined";
import InstagramOutlined from "@ant-design/icons/lib/icons/InstagramOutlined";
import WhatsAppOutlined from "@ant-design/icons/lib/icons/WhatsAppOutlined";
import { Col, Row } from "antd";

const SBFooter = () => {
  return (
    <>
      <Row
        gutter={[0, 8]}
        style={{
          backgroundColor: "#6451ce",
          minHeight: "4em",
          padding: "1em",
          color: "#fff",
        }}
        align="middle"
      >
        <Col
          xs={{ offset: 2, span: 20 }}
          md={{ offset: 2, span: 11 }}
          lg={{ offset: 2, span: 10 }}
        >
          Sosyal Medya Hesaplarımızdan Bize Ulaşın !
        </Col>
        <Col
          xs={{ offset: 2, span: 20 }}
          md={{ offset: 6, span: 5 }}
          lg={{ offset: 8, span: 4 }}
        >
          <WhatsAppOutlined
            onClick={(e) => {
              e.preventDefault();
              window.open("https://wa.me/5514175366", "_blank");
            }}
            style={{ fontSize: "2em", marginRight: "10px", cursor: "pointer" }}
          />
          <InstagramOutlined
            onClick={(e) => {
              e.preventDefault();
              window.open(
                "https://www.instagram.com/bilgic.iletisim",
                "_blank"
              );
            }}
            style={{ fontSize: "2em", marginRight: "10px", cursor: "pointer" }}
          />
          <FacebookOutlined
            onClick={(e) => {
              e.preventDefault();
              window.open("https://www.facebook.com/", "_blank");
            }}
            style={{ fontSize: "2em", marginRight: "10px", cursor: "pointer" }}
          />
        </Col>
      </Row>
      <Row
        style={{
          backgroundColor: "#1c2431",
          color: "#c9d0df",
          paddingTop: "3em",
          paddingBottom: "3em",
        }}
      >
        <Col
          xs={{ offset: 2, span: 20 }}
          md={{ offset: 2, span: 9 }}
          lg={{ offset: 2, span: 5 }}
        >
          <h5 style={{ color: "#fff" }}>Site Haritası</h5>
          <p>
            <a
              style={{ textDecoration: "none", color: "#c9d0df" }}
              href="#anasayfa"
            >
              Anasayfa
            </a>
          </p>
          <p>
            <a
              style={{ textDecoration: "none", color: "#c9d0df" }}
              href="#hizmetlerimiz"
            >
              Hizmetlerimiz
            </a>
          </p>
          <p>
            <a
              style={{ textDecoration: "none", color: "#c9d0df" }}
              href="#vitrin"
            >
              Vitrin
            </a>
          </p>
          <p>
            <a
              style={{ textDecoration: "none", color: "#c9d0df" }}
              href="#iletisim"
            >
              İletişim
            </a>
          </p>
        </Col>
        <Col
          xs={{ offset: 2, span: 20 }}
          md={{ offset: 2, span: 9 }}
          lg={{ offset: 0, span: 5 }}
        >
          <h5 style={{ color: "#fff" }}>İletişim</h5>
          <p>Ufuk Bilgiç : 0507 736 1953</p>
          <p>Çağlar Bilgiç : 0551 417 5366</p>
          <p>bilgiciletisim@outlook.com</p>
        </Col>
        <Col
          xs={{ offset: 2, span: 20 }}
          md={{ offset: 2, span: 9 }}
          lg={{ offset: 0, span: 5 }}
        >
          <h5 style={{ color: "#fff" }}>Çalışma Saatlerimiz</h5>
          <p>Ptesi-Cuma: 09:00 - 19:00</p>
          <p>Ctesi: 09:00 - 17:00</p>
        </Col>
        <Col
          xs={{ offset: 2, span: 20 }}
          md={{ offset: 2, span: 9 }}
          lg={{ offset: 0, span: 5 }}
        >
          <h5 style={{ color: "#fff" }}>Bilgiç Teknoloji</h5>
          <p>Ziyapaşa Mah. Baraj Caddesi No: 16/C Seyhan/Adana</p>
        </Col>
      </Row>
      <Row
        justify="center"
        align="middle"
        style={{ backgroundColor: "#151b29", height: "3em" }}
      >
        <a
          style={{ textDecoration: "none", color: "#fff" }}
          href="https://www.sifirlabir.com/"
        >
          © 2021 Sıfırla Bir Yazılım'ın Ürünüdür. Tüm hakları saklıdır.
        </a>
      </Row>
    </>
  );
};

export default SBFooter;
