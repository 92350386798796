import api from "./AxiosConfig";

const sendSmsIri = "https://sms.sifirlabir.com/api/send-sms";
const getQuantityIri = "https://sms.sifirlabir.com/api/quantity";
const apiId = "147c7ecab79c9a4c0bb92645";
const apiKey = "739744d296534297efe4fb1c";
const originator = "CAGLAR BLGC";

const smsApi = {
  sendSms: (message: string, phones: string[]) => {
    const data = {
      apiId: apiId,
      apiKey: apiKey,
      originator: originator,
      message: message,
      phones: phones,
    };

    return api
      .post(sendSmsIri, data)
      .then((response) => {
        alert("Mesaj gönderildi. Kalan bakiye: " + response.data.quantity);
        return response;
      })
      .catch(() => {
        alert("Mesaj gönderilemedi.");
      });
  },
  getQuantity: () => {
    const data = {
      apiId: apiId,
      apiKey: apiKey,
    };

    return api
      .post(getQuantityIri, data)
      .then((response) => {
        alert("Kalan bakiye: " + response.data.quantity);
        return response;
      })
      .catch(() => {
        alert("Bakiye sorgulanamadı.");
      });
  },
};

export default smsApi;
