import { Layout, Menu, PageHeader, Button } from "antd";
import { useState } from "react";
import { Header } from "antd/lib/layout/layout";
import ProductCrudPage from "./ProductCrudPage";
import OperationCrudPage from "./OperationCrudPage";

const AdminPage = () => {
  const { Sider, Content } = Layout;
  const [contentName, setContentName] = useState<string>("products");

  const handleClick = (e: any) => {
    setContentName(e.key);
  };

  const getContentPage = (contentName: string) => {
    switch (contentName) {
      case "products":
        return <ProductCrudPage />;
      case "operations":
        return <OperationCrudPage />;
      default:
        return <></>;
    }
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header style={{ padding: "0px" }}>
        <PageHeader
          style={{
            backgroundColor: "#7dbcea",
            color: "white",
            height: "64px",
          }}
          title="Bilgiç Teknoloji"
          extra={[
            <Button
              key={2}
              onClick={() => {
                localStorage.clear();
                window.location.reload();
              }}
            >
              Çıkış Yap
            </Button>,
          ]}
        />
      </Header>
      <Layout>
        <Sider>
          <Menu
            onClick={handleClick}
            style={{ width: "100%", height: "100%" }}
            defaultSelectedKeys={[contentName]}
            defaultOpenKeys={["sub1"]}
            mode="inline"
          >
            <Menu.Item key="products">Ürünler</Menu.Item>
            <Menu.Item key="operations">İşlemler</Menu.Item>
          </Menu>
        </Sider>
        <Content style={{ margin: "3em" }}>
          {getContentPage(contentName)}
        </Content>
      </Layout>
    </Layout>
  );
};

export default AdminPage;
