import api from "./AxiosConfig";

export const client = {
  login: (username: string, password: string) => {
    return api
      .post("api/login_check", { username: username, password: password })
      .then((response) => {
        return response.data;
      });
  },
  searchDevice: (imei: string, code: string) => {
    return api
      .post("api/search-device", { imei: imei, code: code })
      .then((response) => {
        return response.data;
      });
  },
  constants: () => {
    return api.get("api/constants").then((response) => response.data);
  },
};
