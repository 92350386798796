import { Redirect, Route } from "react-router-dom";

interface PrivateRouteProps {
  path: string;
  children: any;
  redirectPath?: string;
}

const checkLocalStorageTokens = () => {
  return Boolean(localStorage.getItem("accessToken"));
};

const PrivateRoute = (props: PrivateRouteProps) => {
  return (
    <Route path={props.path}>
      {checkLocalStorageTokens() === (props.redirectPath !== undefined) ? (
        <Redirect
          to={{
            pathname:
              props.redirectPath !== undefined ? props.redirectPath : "/login",
          }}
        />
      ) : (
        props.children
      )}
    </Route>
  );
};

export default PrivateRoute;
