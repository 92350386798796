import axios from "axios";
import { IError } from "../interfaces/IResponse";

const baseURL = process.env.REACT_APP_BASE_URL;

const api = axios.create({
  baseURL: baseURL,
  responseType: "json",
});

api.interceptors.request.use(
  (config) => {
    let headers = {
      "Content-Type": "application/json",
      Authorization: "",
    };

    const accessToken = localStorage.getItem("accessToken");
    if (accessToken && accessToken !== "undefined") {
      headers.Authorization = `Bearer ${accessToken}`;
    }

    config.headers = headers;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    let errorMessage = "";
    if (error.response.data.errorCode === "SB-403") {
      errorMessage = error.response.data.message;
      localStorage.clear();
      window.location.reload();
    } else if (error.response.status === 422) {
      error.response.data.violations.forEach((violation: IError) => {
        errorMessage += violation.message + "\n";
      });
    } else if (error.response.data["hydra:description"]) {
      errorMessage = error.response.data["hydra:description"];
    } else {
      errorMessage = error.response.data.message;
    }

    alert(errorMessage);
    return Promise.reject(error);
  }
);

export default api;
