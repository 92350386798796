import { v4 as uuidv4 } from "uuid";

export interface SBUploadFile {
  name: string;
  uid: string;
  iri?: string;
  size?: number;
  status?: "notYet" | "done" | "uploading" | "error";
  percent?: number;
  rawData?: Blob;
}

export interface ChunkFile {
  index: string;
  file: Blob;
  isLast: "1" | "0";
}

export const getChunksFromFile = (file: Blob, chunkSize: number) => {
  let chunks = [] as ChunkFile[];
  if (file.size < chunkSize) {
    chunks.push({
      index: "0",
      file: file.slice(0, file.size),
      isLast: "1",
    } as ChunkFile);

    return chunks;
  }

  const fullChunkCount = Math.ceil(file.size / chunkSize);
  const remainderSize = file.size % chunkSize;

  let index = 0;
  for (; index < fullChunkCount; index++) {
    const isLastPart = index === fullChunkCount - 1;
    const startPoint = index * chunkSize;
    const endPoint = startPoint + (isLastPart ? remainderSize : chunkSize);
    const filePart = file.slice(startPoint, endPoint);
    chunks.push({
      index: index.toString(),
      file: filePart,
      isLast: isLastPart ? "1" : "0",
    } as ChunkFile);
  }

  return chunks;
};

export const convertToSBUploadFileListFromFileList = (files: FileList) => {
  return Array.from(files).map((file) => {
    return {
      name: file.name,
      uid: uuidv4(),
      iri: "",
      size: file.size,
      status: "notYet",
      percent: 0,
      rawData: file,
    } as SBUploadFile;
  });
};

export default SBUploadFile;
