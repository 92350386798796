import * as React from "react";
var Point = function (_a) {
    var index = _a.index, pointSize = _a.pointSize, pointActiveSize = _a.pointActiveSize, size = _a.size, selected = _a.selected, pop = _a.pop;
    var percentPerItem = 100 / size;
    return (React.createElement("div", { className: "react-pattern-lock__point-wrapper" + (selected ? " selected" : ""), style: {
            width: percentPerItem + "%",
            height: percentPerItem + "%",
            flex: "1 0 " + percentPerItem + "%"
        }, "data-index": index },
        React.createElement("div", { className: "react-pattern-lock__point", style: {
                width: pointActiveSize,
                height: pointActiveSize
            } },
            React.createElement("div", { className: "react-pattern-lock__point-inner" + (pop ? " active" : ""), style: {
                    minWidth: pointSize,
                    minHeight: pointSize
                } }))));
};
export default Point;
