import { useState } from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import { Modal, Form, Input } from "antd";
import LoadingOutlined from "@ant-design/icons/lib/icons/LoadingOutlined";
import { getDateString } from "../helper/DateFormatter";
import { IOperation } from "../interfaces/IEntity";
import { client } from "../client/Client";

const SBNavbar = () => {
  const [form] = Form.useForm();
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<IOperation>();
  const [error, setError] = useState<string>();

  return (
    <Navbar bg="white" expand="lg" sticky="top">
      <Container>
        <Navbar.Brand>
          <img
            src="logo.png"
            width="180"
            height="50"
            className="d-inline-block align-top"
            alt="logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse
          id="basic-navbar-nav"
          className="justify-content-center"
        >
          <Nav className="justify-content-center">
            <a className="nav-link" href={"#anasayfa"}>
              Anasayfa
            </a>
            <a className="nav-link" href={"#hizmetlerimiz"}>
              Hizmetlerimiz
            </a>
            <a className="nav-link" href={"#vitrin"}>
              Vitrin
            </a>
            <a className="nav-link" href={"#iletisim"}>
              İletişim
            </a>
            <a
              href="#cihaz"
              className="nav-link"
              onClick={(e) => {
                e.preventDefault();
                setModalIsOpen(true);
              }}
            >
              Cihaz Durumu Sorgula
            </a>
          </Nav>
        </Navbar.Collapse>
      </Container>
      <Modal
        title="Cihaz Durumu Sorgula"
        visible={modalIsOpen}
        onOk={() => {
          form.validateFields().then((values) => {
            setLoading(true);
            setData(undefined);
            setError(undefined);
            client
              .searchDevice(values.imei, values.code)
              .then((response) => {
                if (response.statusCode === 200) {
                  setData(response.data);
                } else {
                  setError(response.message);
                }
              })
              .finally(() => {
                setLoading(false);
              });
          });
        }}
        onCancel={() => {
          form.resetFields();
          setData(undefined);
          setError(undefined);
          setModalIsOpen(false);
        }}
        cancelText="Kapat"
        okText="Sorgula"
      >
        <p>
          Servisimize teslim etmiş olduğunuz cihazınızın güncel durumunu
          sorgulamak için lütfen aşağıda bulunan alanlara imei ve güvenlik
          kodunuzu girerek sorgula butonuna basınız.
        </p>
        <Form form={form}>
          <Form.Item label="Imei Numarası" name="imei">
            <Input />
          </Form.Item>
          <Form.Item
            label="Güvenlik Kodu"
            name="code"
            rules={[{ required: true, message: "Boş bırakılamaz!" }]}
          >
            <Input />
          </Form.Item>
        </Form>
        <p style={{ color: "red" }}>
          <b>Not:</b> Güvenlik kodu, cihazınızı servisimize teslim ettiğinizde
          size sözlü, sms olarak yada e-posta olarak iletilen 8 haneli şifredir.
        </p>
        {loading && <LoadingOutlined />}
        {data && (
          <div style={{ marginTop: "25px" }}>
            <p>
              İsim : {data.fullname} <br />
              <br />
              Cihaz Adı : {data.deviceName} <br />
              <br />
              Cihazın Geliş Tarihi : {getDateString(data.createdAt, "short")}
              <br />
              <br />
              Fiyat : {data.price ? data.price + " TL" : ""}
              <br />
              <br />
              Cihazın Gelme Sebebi : {data.deviceProblem} <br />
              <br />
              Yapılan İşlemler : {data.madeOperations} <br />
              <br />
            </p>
          </div>
        )}
        {error && (
          <div style={{ marginTop: "25px" }}>
            <p> {error}</p>
          </div>
        )}
      </Modal>
    </Navbar>
  );
};

export default SBNavbar;
