const shortMonths = [
  "Oc",
  "Şu",
  "Mar",
  "Nis",
  "May",
  "Haz",
  "Tem",
  "Agu",
  "Ey",
  "Ek",
  "Kas",
  "Ara",
];

const longMonths = [
  "Ocak",
  "Şubat",
  "Mart",
  "Nisan",
  "Mayıs",
  "Haziran",
  "Temmuz",
  "Ağustos",
  "Eylül",
  "Ekim",
  "Kasım",
  "Aralık",
];

export const getDateString = (date: string | Date, mode?: string) => {
  mode = mode ?? "short";
  if (typeof date === "string") {
    date = new Date(date);
  }

  return `${date.getDate()} ${
    mode === "long" ? longMonths[date.getMonth()] : shortMonths[date.getMonth()]
  } ${date.getFullYear()} ${
    mode === "long" ? date.getUTCHours() + "" + date.getMinutes() : ""
  }`;
};

export const getGlobalDate = (date: string | Date, mode?: string) => {
  if (typeof date === "string") {
    date = new Date(date);
  }

  return `${date.getDate()}.${date.getMonth()}.${date.getFullYear()}`;
};

export const isDateToday = (date: Date) => {
  const currentDate = new Date();
  const currentDay = currentDate.getUTCDate();
  const currentMonth = currentDate.getUTCMonth();
  const currentYear = currentDate.getUTCFullYear();

  return (
    currentDay === date.getUTCDate() &&
    currentMonth === date.getUTCMonth() &&
    currentYear === date.getUTCFullYear()
  );
};
