import { Card } from "antd";
import Meta from "antd/lib/card/Meta";
import MultiCarousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export interface ISlide {
  title: string;
  price?: string;
  src?: string;
}

interface IMiniSliderProps {
  slides: ISlide[];
  infinite?: boolean;
}

const MiniSlider = (props: IMiniSliderProps) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <MultiCarousel responsive={responsive} autoPlaySpeed={1000} autoPlay={true}>
      {props.slides.map((slide, index) => {
        return (
          <div key={index}>
            <Card
              style={{ margin: "1em" }}
              cover={<img src={slide.src ? slide.src : "default.png"} alt="" />}
            >
              <Meta title={slide.title} description={slide.price} />
            </Card>
          </div>
        );
      })}
    </MultiCarousel>
  );
};

export default MiniSlider;
