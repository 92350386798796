import { Card, Col, Row } from "antd";
import Meta from "antd/lib/card/Meta";
import { useEffect, useState } from "react";
import { productCrudApi } from "../client/EntityApi";
import MiniSlider, { ISlide } from "../components/MiniSlider";
import SBCarousel from "../components/SBCarousel";
import SBFooter from "../components/SBFooter";
import SBNavbar from "../components/SBNavbar";
import { IProduct } from "../interfaces/IEntity";
import IListResponse from "../interfaces/IResponse";

const MainPage = () => {
  const [slides, setSlides] = useState<ISlide[]>([]);

  useEffect(() => {
    productCrudApi.getAll().then((response: IListResponse<IProduct>) => {
      const slides = response["hydra:member"].map((product) => {
        return {
          title: product.title,
          src: product.media
            ? process.env.REACT_APP_BASE_URL + product.media.name
            : undefined,
          price: product.price,
        };
      });
      setSlides(slides);
    });
  }, []);

  return (
    <>
      <SBNavbar />
      <div id="anasayfa" />
      <SBCarousel
        items={[
          { src: "slider-1.png", alt: "" },
          { src: "slider-2.png", alt: "" },
          { src: "slider-3.png", alt: "" },
        ]}
      />
      <br id="hizmetlerimiz" />
      <br />
      <br />
      <Row align="middle" justify="center">
        <h3>Teknik Hizmetlerimiz</h3>
      </Row>
      <br />
      <Row gutter={[0, 24]}>
        <Col
          xs={{ offset: 2, span: 20 }}
          md={{ offset: 3, span: 8 }}
          lg={{ offset: 2, span: 6 }}
        >
          <Card cover={<img src="service-technic-1.png" alt="" />}>
            <Meta title="Ekran Değişimi" />
          </Card>
        </Col>
        <Col
          xs={{ offset: 2, span: 20 }}
          md={{ offset: 2, span: 8 }}
          lg={{ offset: 1, span: 6 }}
        >
          <Card cover={<img src="service-technic-2.png" alt="" />}>
            <Meta title="Orjinal Ekranlara Cam Değişimi" />
          </Card>
        </Col>
        <Col
          xs={{ offset: 2, span: 20 }}
          md={{ offset: 3, span: 8 }}
          lg={{ offset: 1, span: 6 }}
        >
          <Card cover={<img src="service-technic-3.png" alt="" />}>
            <Meta title="Batarya Değişimi" />
          </Card>
        </Col>
        <Col
          xs={{ offset: 2, span: 20 }}
          md={{ offset: 2, span: 8 }}
          lg={{ offset: 2, span: 6 }}
        >
          <Card cover={<img src="service-technic-4.png" alt="" />}>
            <Meta title="Anakart Tamiri" />
          </Card>
        </Col>
        <Col
          xs={{ offset: 2, span: 20 }}
          md={{ offset: 3, span: 8 }}
          lg={{ offset: 1, span: 6 }}
        >
          <Card cover={<img src="service-technic-5.png" alt="" />}>
            <Meta title="Soket Tamiri" />
          </Card>
        </Col>
        <Col
          xs={{ offset: 2, span: 20 }}
          md={{ offset: 2, span: 8 }}
          lg={{ offset: 1, span: 6 }}
        >
          <Card cover={<img src="service-technic-6.png" alt="" />}>
            <Meta title="Telefon Yazılım İşlemleri" />
          </Card>
        </Col>
      </Row>
      <br />
      <Row align="middle" justify="center">
        <h3>Satış İşlemleri</h3>
      </Row>
      <br />
      <Row gutter={[0, 24]}>
        <Col
          xs={{ offset: 2, span: 20 }}
          md={{ offset: 3, span: 8 }}
          lg={{ offset: 2, span: 6 }}
        >
          <Card cover={<img src="service-sell-1.png" alt="" />}>
            <Meta title="Sıfır Telefon Satışı" />
          </Card>
        </Col>
        <Col
          xs={{ offset: 2, span: 20 }}
          md={{ offset: 2, span: 8 }}
          lg={{ offset: 1, span: 6 }}
        >
          <Card cover={<img src="service-sell-2.png" alt="" />}>
            <Meta title="2. El Telefon Satışı" />
          </Card>
        </Col>
        <Col
          xs={{ offset: 2, span: 20 }}
          md={{ offset: 3, span: 8 }}
          lg={{ offset: 1, span: 6 }}
        >
          <Card cover={<img src="service-sell-3.png" alt="" />}>
            <Meta title="Telefon Aksesuarları" />
          </Card>
        </Col>
        <Col
          xs={{ offset: 2, span: 20 }}
          md={{ offset: 2, span: 8 }}
          lg={{ offset: 2, span: 6 }}
        >
          <Card cover={<img src="service-sell-4.png" alt="" />}>
            <Meta title="Özel Kesim Nano Cam Jelatini" />
          </Card>
        </Col>
        <Col
          xs={{ offset: 2, span: 20 }}
          md={{ offset: 3, span: 8 }}
          lg={{ offset: 1, span: 6 }}
        >
          <Card cover={<img src="service-sell-5.png" alt="" />}>
            <Meta title="Fatura Ödeme Merkezi" />
          </Card>
        </Col>
      </Row>
      <br id="vitrin" />
      <br />
      <br />
      <Row align="middle" justify="center">
        <h3>Vitrin</h3>
      </Row>
      <br />
      <MiniSlider slides={slides} />
      <br />
      <Row>
        <Col offset={2} span={20} style={{ minHeight: "30vw" }}>
          <iframe
            title="unique"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12744.255046727456!2d35.3243214!3d37.0082752!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf95d79c04b773fd9!2sBilgi%C3%A7%20Teknoloji!5e0!3m2!1str!2str!4v1640032147227!5m2!1str!2str"
            width="100%"
            height="100%"
            loading="lazy"
          />
        </Col>
      </Row>
      <div id="iletisim" />
      <br />
      <SBFooter />
    </>
  );
};

export default MainPage;
