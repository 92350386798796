import { Form, Input, InputNumber } from "antd";
import { useState } from "react";
import { productCrudApi } from "../client/EntityApi";
import CrudPage from "../components/CrudPage";
import ZeroPoint from "../components/ZeroPoint";
import { SBUploadFile } from "../components/ZeroPointHelper";

const ProductCrudPage = () => {
  const [formAdd] = Form.useForm();
  const [formEdit] = Form.useForm();

  const [selectedMedia, setSelectedMedia] = useState<SBUploadFile>();

  const getFormItems = (formType: string) => {
    return (
      <>
        <Form.Item
          label="Ürün Adı"
          name="title"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          rules={[
            { required: true, message: "Boş bırakılamaz." },
            { max: 30, message: "En fazla 30 karakter olmalıdır." },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Fiyat"
          name="price"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item name="media">
          <ZeroPoint
            fileList={selectedMedia ? [selectedMedia] : undefined}
            acceptExtensions="image/*"
            buttonStyle={{ width: "100%" }}
            setMediaId={(mediaId) => {
              if (formType === "add") {
                formAdd.setFieldsValue({
                  media: mediaId ? "api/media/" + mediaId : null,
                });
              } else {
                formEdit.setFieldsValue({
                  media: mediaId ? "api/media/" + mediaId : null,
                });
              }
            }}
            setMediaIds={() => {}}
          />
        </Form.Item>
      </>
    );
  };

  const columns = [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Ürün Adı",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Fiyat",
      dataIndex: "price",
      key: "price",
    },
  ];

  return (
    <>
      <CrudPage
        entityLabel="Ürün"
        searchKey="title"
        api={productCrudApi}
        getAll={productCrudApi.getAll}
        columns={columns}
        addForm={getFormItems("add")}
        formAdd={formAdd}
        beforeAddManipulationOnValues={(values) => {
          if (values.price) {
            values.price = values.price.toString();
          }
          return values;
        }}
        editForm={getFormItems("edit")}
        formEdit={formEdit}
        beforeEditManipulationOnValues={(values) => {
          if (values.price) {
            values.price = values.price.toString();
          }
          return values;
        }}
        afterEditOperation={() => {
          setSelectedMedia(undefined);
        }}
        setEditFields={(row: any) => {
          if (row.media) {
            setSelectedMedia({
              name: row.media.originalName,
              uid: row.media.name,
              iri: row.media.id,
              status: "done",
              percent: 100,
            });
          }

          return {
            title: row.title,
            price: row.price,
          };
        }}
      />
    </>
  );
};

export default ProductCrudPage;
