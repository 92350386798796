import { Carousel } from "antd";
import { Link } from "react-router-dom";

interface ISBCarouselProps {
  items: ISBCarouselItem[];
}

interface ISBCarouselItem {
  src: string;
  alt?: string;
  href?: string;
}

const SBCarousel = (props: ISBCarouselProps) => {
  return (
    <Carousel autoplay>
      {props.items.map((item) => {
        return (
          <div>
            {item.href ? (
              <Link to={item.href}>
                <img
                  style={{ maxHeight: "90vh", width: "100%" }}
                  src={item.src}
                  alt={item.alt}
                />
              </Link>
            ) : (
              <img
                style={{ maxHeight: "90vh", width: "100%" }}
                src={item.src}
                alt={item.alt}
              />
            )}
          </div>
        );
      })}
    </Carousel>
  );
};

export default SBCarousel;
