import { Button, Col, Progress, Row } from "antd";
import {
  UploadOutlined,
  DeleteOutlined,
  FileImageOutlined,
} from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import SBUploadFile, {
  convertToSBUploadFileListFromFileList,
  getChunksFromFile,
} from "./ZeroPointHelper";
import api from "../client/AxiosConfig";

interface ZeroPointProps {
  uploadText?: string;
  buttonStyle?: object;
  acceptExtensions?: string;
  multiple?: boolean;
  patchableList?: boolean;
  fileList?: SBUploadFile[];
  setMediaId: (id?: number) => void;
  setMediaIds: (ids?: number[]) => void;
}

const ZeroPoint = (props: ZeroPointProps) => {
  let inputRef = useRef<HTMLInputElement>(null);
  const [fileList, setFileList] = useState<SBUploadFile[]>([]);

  const chunkUpload = async (file: SBUploadFile, fileList: SBUploadFile[]) => {
    const chunkSize = 1000000;
    const chunks = getChunksFromFile(file.rawData!, chunkSize);
    for (let i = 0; i < chunks.length; i++) {
      let formData = new FormData();
      formData.append("file", chunks[i].file);
      formData.append("index", chunks[i].index);
      formData.append("last", chunks[i].isLast);
      formData.append("uuid", file.uid);
      formData.append("orig", file.name);
      await api
        .post("api/upload", formData)
        .then((response) => {
          const percent =
            response.status === 201
              ? 100
              : Math.floor(
                  (chunkSize * 100 * (parseInt(chunks[i].index) + 1)) /
                    file.rawData!.size
                );

          setFileList(
            updatePercent(file.uid, percent, fileList, response.data.id)
          );
        })
        .catch(() => {
          setFileList(updatePercent(file.uid, -1, fileList));
        });
    }
  };

  const updatePercent = (
    uid: String,
    percent: number,
    fileList: SBUploadFile[],
    id?: string
  ) => {
    return fileList.map((item) => {
      if (item.uid === uid) {
        item.percent = percent;
        item.status =
          percent === 100 ? "done" : percent === -1 ? "error" : "uploading";
        if (id) {
          item.iri = id;
        }
      }

      return item;
    });
  };

  const removeFileOnList = (file: SBUploadFile) => {
    setFileList(
      fileList.filter((f) => {
        return f.uid !== file.uid;
      })
    );
  };

  useEffect(() => {
    setFileList(props.fileList ?? []);
  }, [props.fileList]);

  useEffect(() => {
    if (props.multiple) {
      props.setMediaIds([]);
      const SBUploadFiles = fileList.filter((file) => {
        return file.status === "done" && file.iri;
      });
      const mediaIds = SBUploadFiles.map((SBUploadFile) => {
        return parseInt(SBUploadFile.iri!);
      });

      props.setMediaIds(mediaIds);
    } else {
      props.setMediaId(undefined);
      fileList.forEach((file) => {
        if (file.status === "done" && file.iri) {
          props.setMediaId(parseInt(file.iri));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList]);

  return (
    <>
      <Button
        style={props.buttonStyle}
        icon={<UploadOutlined />}
        onClick={() => {
          inputRef?.current?.click();
        }}
      >
        {props.uploadText ?? "Dosya Seç"}
      </Button>
      <input
        accept={
          props.acceptExtensions ? props.acceptExtensions : "file_extensions"
        }
        multiple={props.multiple}
        style={{ display: "none" }}
        type="file"
        ref={inputRef}
        onChange={(e) => {
          if (e.target.files) {
            const files = convertToSBUploadFileListFromFileList(e.target.files);
            const toBeUploadFileList = props.patchableList
              ? [...fileList, ...files]
              : [...files];
            setFileList(toBeUploadFileList);
            toBeUploadFileList.forEach((toBeUploadFile) => {
              if (toBeUploadFile.status === "notYet") {
                chunkUpload(toBeUploadFile, toBeUploadFileList);
              }
            });
          }
        }}
      />
      {fileList.map((file: any) => {
        return (
          <Row
            style={{
              border: "1px solid #d9d9d9",
              padding: "1em",
              marginTop: "0.5em",
              marginBottom: "0.5em",
            }}
            align="middle"
          >
            <Col span={3}>
              <FileImageOutlined
                style={{ fontSize: "26px", color: "#1890ff" }}
              />
            </Col>
            <Col span={7}>{file.name}</Col>
            <Col offset={12} span={2}>
              <Button
                style={{
                  backgroundColor: "transparent",
                  borderColor: "transparent",
                }}
                icon={<DeleteOutlined style={{ fontSize: "14px" }} />}
                onClick={() => removeFileOnList(file)}
              />
            </Col>
            <Progress percent={file.percent} size="small" />
          </Row>
        );
      })}
    </>
  );
};

export default ZeroPoint;
