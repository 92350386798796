import { Switch, Route, BrowserRouter } from "react-router-dom";
import MainPage from "./pages/MainPage";
import "antd/dist/antd.css";
import "bootstrap/dist/css/bootstrap.min.css";
import LoginPage from "./pages/LoginPage";
import PrivateRoute from "./components/PrivateRoute";
import AdminPage from "./pages/AdminPage";

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <PrivateRoute path="/login" redirectPath="/admin">
          <LoginPage />
        </PrivateRoute>
        <PrivateRoute path="/admin">
          <AdminPage />
        </PrivateRoute>
        <Route exact path="/">
          <MainPage />
        </Route>
        <Route>404 Not Found!</Route>
      </Switch>
    </BrowserRouter>
  );
};

export default App;
