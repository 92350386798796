import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Tooltip,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { operationCrudApi } from "../client/EntityApi";
import CrudPage from "../components/CrudPage";
import PatternLock from "react-pattern-lock";
import { useEffect, useState } from "react";
import {
  MessageOutlined,
  PrinterOutlined,
  DatabaseOutlined,
} from "@ant-design/icons";
import smsApi from "../client/SmsApi";
import { getDateString } from "../helper/DateFormatter";
import PrintComponents from "react-print-components";
import { client } from "../client/Client";

const OperationCrudPage = () => {
  const [pattern, setPattern] = useState<number[]>([]);
  const [states, setStates] = useState<any[]>([]);

  useEffect(() => {
    client.constants().then((response: any) => setStates(response["state"]));
  }, []);

  const getConstantValue = (value: any, constants: any[]) => {
    const findedConstant = constants.find(
      (constant) => constant.value === value
    );
    return findedConstant ? findedConstant.label : "";
  };

  const columns = [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "İsim / Soyisim",
      dataIndex: "fullname",
      key: "fullname",
    },
    {
      title: "Cihaz Adı",
      dataIndex: "deviceName",
      key: "deviceName",
    },
    {
      title: "Kod",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Telefon",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Imei",
      dataIndex: "imei",
      key: "imei",
    },
    {
      title: "Durum",
      dataIndex: "state",
      key: "state",
      render: (value: any) => getConstantValue(value, states),
      filters: states,
      onFilter: (value: any, record: any) => record.state === value,
      defaultFilteredValue: ["wait", "done", "return"],
    },
    {
      title: "PIN",
      dataIndex: "pin",
      key: "pin",
      render: (value: any) => value ?? "-",
    },
    {
      title: "Desen",
      dataIndex: "pattern",
      key: "pattern",
      render: (value: any) => {
        return value.length > 0
          ? value.map((value: number) => value + 1).toString()
          : "-";
      },
    },
    {
      title: "Oluşturulma Tarihi",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value: any) => getDateString(value),
    },
  ];

  const form = (
    <>
      <Form.Item
        label="İsim / Soyisim"
        name="fullname"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        rules={[
          { required: true, message: "Boş bırakılamaz." },
          { max: 50, message: "En fazla 50 karakter olmalıdır." },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Cihaz Adı"
        name="deviceName"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        rules={[
          { required: true, message: "Boş bırakılamaz." },
          { max: 100, message: "En fazla 100 karakter olmalıdır." },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Telefon"
        name="phone"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        rules={[
          { len: 10, message: "Telefon +90 hariç 10 karakter olmalıdır." },
        ]}
      >
        <Input addonBefore={"+90"} />
      </Form.Item>
      <Form.Item
        label="Imei"
        name="imei"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        rules={[
          { min: 13, message: "En az 13 karakter olmalıdır." },
          { max: 16, message: "En fazla 16 karakter olmalıdır." },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Fiyat"
        name="price"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
      >
        <InputNumber />
      </Form.Item>
      <Form.Item
        label="Cihaz Problemi"
        name="deviceProblem"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
      >
        <TextArea />
      </Form.Item>
      <Form.Item
        label="Yapılan İşlemler"
        name="madeOperation"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
      >
        <TextArea />
      </Form.Item>
      <Form.Item
        label="Durum"
        name="state"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        initialValue="wait"
      >
        <Select options={states} />
      </Form.Item>
      <Form.Item
        label="PIN"
        name="pin"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
      >
        <Input />
      </Form.Item>
      <Form.Item name="pattern">
        <Row justify="center" align="middle">
          <Col offset={4} span={16}>
            <PatternLock
              style={{ backgroundColor: "black" }}
              width="40%"
              pointSize={15}
              size={3}
              connectorThickness={5}
              path={pattern}
              onChange={(e) => setPattern(e)}
              onFinish={() => {}}
            />
          </Col>
          <Col offset={4} span={16}>
            <div>
              {pattern
                ?.map((value) => {
                  return value + 1;
                })
                .toString()}
            </div>
          </Col>
          <Col offset={4} span={16}>
            <Button
              onClick={() => {
                setPattern([]);
              }}
            >
              Deseni Sıfırla
            </Button>
          </Col>
        </Row>
      </Form.Item>
    </>
  );

  return (
    <>
      <CrudPage
        titleProceses={() => {
          return (
            <Tooltip title="Bakiye Sorgula">
              <Button
                style={{ marginRight: "0.5em" }}
                onClick={() => smsApi.getQuantity()}
                shape="circle"
                icon={<DatabaseOutlined />}
              />
            </Tooltip>
          );
        }}
        proceses={(row: any) => {
          return (
            <>
              <Tooltip title="Sms Gönder">
                <Button
                  disabled={!row.phone}
                  style={{ marginRight: "0.5em" }}
                  onClick={() => {
                    smsApi.sendSms(
                      "Cihanızın işlemi Bilgiç Teknoloji tarafından tamamlanmıştır. Detaylı işlem sorgulama için www.bilgicteknoloji.com adresinden sorgulayabilir, cihazınızı teslim almak için şubemize gelebilirsiniz.",
                      [row.phone]
                    );
                  }}
                  shape="circle"
                  icon={<MessageOutlined />}
                />
              </Tooltip>

              <PrintComponents
                trigger={
                  <Tooltip title=" Fiş Yazdır">
                    <Button
                      style={{ marginRight: "0.5em" }}
                      shape="circle"
                      icon={<PrinterOutlined />}
                    />
                  </Tooltip>
                }
              >
                <div style={{ width: "100mm", height: "50mm" }}>
                  <h5 style={{ fontSize: "14px" }}>
                    <Row justify="center" align="middle">
                      <b style={{ fontSize: "20px" }}>
                        Bilgiç Teknoloji - 0551 417 5366
                      </b>
                    </Row>
                    <br />
                    <b>Cihaz Adı: </b> {row.deviceName} <br />
                    <Row>
                      {row.fullname ? (
                        <Col span={12}>
                          <b>İsim: </b> {row.fullname}
                        </Col>
                      ) : (
                        <></>
                      )}
                      {row.phone ? (
                        <Col span={12}>
                          <b>Telefon: </b> {row.phone}
                        </Col>
                      ) : (
                        <></>
                      )}
                      {row.price ? (
                        <Col span={12}>
                          <b>Fiyat: </b> {row.price} tl
                        </Col>
                      ) : (
                        <></>
                      )}
                      {row.imei ? (
                        <Col span={12}>
                          <b>IMEI: </b> {row.imei} <br />
                        </Col>
                      ) : (
                        <></>
                      )}
                      {row.pattern.length > 0 ? (
                        <Col span={12}>
                          <b>Desen: </b>
                          {row.pattern?.map((e: number) => {
                            return e + 1;
                          })}
                        </Col>
                      ) : (
                        <></>
                      )}
                      {row.pin ? (
                        <Col span={12}>
                          <b>PIN: </b> {row.pin} <br />
                        </Col>
                      ) : (
                        <></>
                      )}
                      <Col span={12}>
                        <b>Kod: </b> {row.code} <br />
                      </Col>
                      <Col span={12}>
                        <b>Tarih: </b> {getDateString(row.createdAt, "short")}{" "}
                        <br />
                      </Col>
                      {row.deviceProblem ? (
                        <Col span={24}>
                          <b>Arıza: </b> {row.deviceProblem}
                        </Col>
                      ) : (
                        <></>
                      )}
                    </Row>
                  </h5>
                </div>
              </PrintComponents>
            </>
          );
        }}
        expandable={{
          expandedRowRender: (record: any) => (
            <>
              <div style={{ padding: "50px" }}>
                <h6>
                  <b>Cihaz Problemi</b>
                </h6>
                <p>{record.deviceProblem}</p>
                <h6>
                  <b>Yapılan İşlemler</b>
                </h6>
                <p>{record.madeOperation}</p>
                <h6>
                  <b>Fiyat</b>
                </h6>
                <p>{record.price}</p>
                <h6>
                  <b>PIN</b>
                </h6>
                <p>{record.pin}</p>
                <h6>
                  <b>
                    Desen Şifresi :{" "}
                    {record.pattern
                      ?.map((value: number) => {
                        return value + 1;
                      })
                      .toString()}
                  </b>
                </h6>
                <Row justify="center" align="middle">
                  <Col span={24}>
                    <PatternLock
                      style={{ backgroundColor: "black" }}
                      width="20%"
                      pointSize={15}
                      size={3}
                      connectorThickness={5}
                      path={record.pattern}
                      onChange={() => {}}
                      onFinish={() => {}}
                    />
                  </Col>
                </Row>
              </div>
            </>
          ),
        }}
        entityLabel="İşlem"
        searchKey="fullname"
        api={operationCrudApi}
        getAll={operationCrudApi.getAll}
        columns={columns}
        addForm={form}
        beforeAddManipulationOnValues={(values) => {
          if (values.price) values.price = values.price.toString();
          values.pattern = pattern;

          return values;
        }}
        afterAddOperation={() => setPattern([])}
        afterEditOperation={() => setPattern([])}
        editForm={form}
        beforeEditManipulationOnValues={(values) => {
          if (values.price) values.price = values.price.toString();
          values.pattern = pattern;

          if (values.deviceProblem === "") values.deviceProblem = null;
          if (values.imei === "") values.imei = null;
          if (values.madeOperation === "") values.madeOperation = null;
          if (values.phone === "") values.phone = null;

          return values;
        }}
        setEditFields={(row: any) => {
          setPattern(row.pattern);
          return {
            ...row,
          };
        }}
      />
    </>
  );
};

export default OperationCrudPage;
