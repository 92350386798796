import { Form, Input, Button, Row, Col, Divider } from "antd";
import { client } from "../client/Client";

const LoginPage = () => {
  const onFinish = (values: any) => {
    client.login(values.username, values.password).then((response) => {
      localStorage.setItem("accessToken", response.token);
      window.location.reload();
    });
  };

  return (
    <Row
      align="middle"
      style={{
        height: "100vh",
        backgroundImage: "linear-gradient(#7598e7, #c533dd)",
      }}
    >
      <Col
        offset={8}
        span={8}
        style={{
          backgroundColor: "white",
          minHeight: "30vh",
          borderRadius: "5px",
          padding: "2em",
        }}
      >
        <Row align="middle" justify="center">
          <h4>Bilgiç Teknoloji Admin Paneli</h4>
        </Row>
        <Divider />
        <Form layout="vertical" onFinish={onFinish}>
          <Form.Item
            label="Kullanıcı Adı"
            name="username"
            rules={[
              { required: true, message: "Kullanıcı adı boş bırakılamaz." },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Şifre"
            name="password"
            rules={[{ required: true, message: "Şifre boş bırakılamaz." }]}
          >
            <Input.Password />
          </Form.Item>
          <Divider />
          <Form.Item wrapperCol={{ span: 24 }}>
            <Button style={{ width: "100%" }} type="primary" htmlType="submit">
              Giriş Yap
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default LoginPage;
